import React from 'react';
import './about.css';
import ME from '../../assets/coleand.jpg';
import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>10+ Years Working in Software Development</small>
            </article>

            {/* <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>200+ Worldwide</small>
            </article> */}

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>30+ Completed</small>
            </article>
          </div>

          <p>
            Globally experienced Full Stack / Mobile / XR Developer and
            Technical Project Manager with excellent problem-solving skills as
            well as a proven ability to pick up new frameworks / technologies
            quickly. A team player and personable leader with outstanding soft
            skills well versed in Agile/Scrum. Spent the last 8 years working (6
            years remote) in the US as a Software Consultant for a Fortune 500
            technology company where I provided both innovative technical
            solutions to customers as well as internally manage teams of
            developers.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
